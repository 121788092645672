import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useRef,
  useEffect,
  ComponentType
} from 'react'
import {
  motion,
  AnimatePresence,
  MotionProps,
  HTMLMotionProps
} from 'framer-motion'
import styled, { CSSObject } from '@emotion/styled'
import { DefaultTheme } from 'assets/theme/theme'
import useOnClickOutside from 'shared/state/useOnClickOutside'
import useTrapFocus from 'shared/state/useTrapFocus'
import { KeyEvents } from 'shared/enums/keys'
import usePageRouter from 'shared/state/usePageRouter'
import reduceTimeForTest from 'shared/helpers/reduceTimeForTest'

type ModalElement = {
  id: string | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Content: ComponentType<any>
  clickOutsideToClose?: boolean
}

/** Type to be used in pages where we are switching modals */
type ActiveModal = ModalElement

export type ModalConfig = { [key: string]: ModalElement }

export type SetActiveModalId<MODAL_IDS> = Dispatch<
  SetStateAction<MODAL_IDS | null>
>

export type ActiveModalId = string | null

export interface ActiveModalProps {
  activeModal: ActiveModal | null
  activeModalId: ActiveModalId
  setActiveModalId: Dispatch<SetStateAction<ActiveModalId>>
}

/**
 * Remove setActiveModalId from all types.
 *
 * While configuring the modals some of the components might request the setActiveModalId,
 * since this type is added by useModal afterwards, we can use this recursive to remove setActiveModalId
 * while setting up the modals.
 */
export type RecursiveOmitSetActiveModalId<T> = {
  [K in keyof T]: Omit<T[K], 'setActiveModalId'>
}

export interface ModalProps {
  isOpen: boolean
  children: ReactNode
  animationVariant?: 'bottomToMiddle' | 'rightToLeft' | 'rightToLeftWithOverlay'
  modalKey?: string
  className?: string
  fullWindow?: boolean
  modalTitle?: string
  activeModalId?: ActiveModalId
  onClickOutside?: () => void
  setActiveModalId?: Dispatch<SetStateAction<ActiveModalId>>
}

export const MODAL_MIXINS = {
  stickToBottom: (theme: DefaultTheme): CSSObject => ({
    alignItems: 'flex-end',
    [`${StyledModalContainer}`]: {
      padding: 0,
      position: 'fixed',
      bottom: 0,
      maxHeight: '100%',
      display: 'flex',
      overflowY: 'auto',
      ...theme.mixins.centeredContainer('xl'),
      flexDirection: 'column',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0
    }
  }),
  sideMenu: (theme: DefaultTheme): CSSObject => ({
    alignItems: 'flex-end',
    [`${StyledModalContainer}`]: {
      padding: 0,
      margin: 0,
      position: 'fixed',
      right: 0,
      top: 0,
      height: '100%',
      maxHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 0,
      width: '100%',
      border: 0,
      [theme.mediaQueries.sm]: {
        width: theme.contentMaxWidth.xs
      }
    }
  }),
  popup: (theme: DefaultTheme): CSSObject => ({
    [`${StyledModalContainer}`]: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      margin: theme.mixins.pxSpread([0, theme.space[4]]),
      padding: theme.mixins.pxSpread([theme.space[7], theme.space[5]]),
      '> * + *': {
        marginTop: theme.space[5]
      }
    }
  }),
  stickToRight: (): CSSObject => ({
    [`${StyledModalContainer}`]: {
      position: 'fixed',
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
      height: '100%',
      borderRadius: 0
    }
  }),
  voucher: (theme: DefaultTheme): CSSObject => ({
    [`${StyledModalContainer}`]: {
      width: theme.contentMaxWidth.sm,
      margin: theme.mixins.pxSpread([0, theme.space[4]]),
      padding: theme.mixins.pxSpread([theme.space[7], theme.space[5]])
    }
  })
}

const ANIMATION_PROPS_MODAL_RTL = {
  initial: { x: 1000 },
  animate: { x: 0 },
  exit: {
    x: 1000
  }
}

const ANIMATION_PROPS_OVERLAY = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
}

const ANIMATION_PROPS: {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [keys in Exclude<ModalProps['animationVariant'], undefined>]: {
    overlay?: MotionProps
    modal: MotionProps
  }
} = {
  rightToLeft: {
    overlay: {},
    modal: ANIMATION_PROPS_MODAL_RTL
  },
  rightToLeftWithOverlay: {
    overlay: ANIMATION_PROPS_OVERLAY,
    modal: ANIMATION_PROPS_MODAL_RTL
  },
  bottomToMiddle: {
    overlay: ANIMATION_PROPS_OVERLAY,
    modal: {
      initial: { y: 1000, opacity: 0 },
      animate: { y: 0, opacity: 1 },
      exit: {
        y: 1000,
        opacity: 0
      }
    }
  }
}

const Modal = ({
  children,
  isOpen,
  className,
  modalKey = 'modal',
  animationVariant = 'bottomToMiddle',
  fullWindow = false,
  modalTitle,
  onClickOutside
}: ModalProps) => {
  const { activeModalId, setActiveModalId } = usePageRouter()
  const modalContainerRef = useRef(null)

  /**
   * Close modal when the key Escape is pressed.
   *
   * The keydown event listener is added to the document instead of using
   * React's onKeyDown event to detect keydown anywhere on the page.
   */
  useEffect(() => {
    if (!activeModalId) return

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key !== KeyEvents.escape) return
      e.preventDefault()
      setActiveModalId(null)
    }

    document.addEventListener('keydown', handleKeyDown)

    // Clean up
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [activeModalId, setActiveModalId])

  useOnClickOutside({
    refs: [modalContainerRef],
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClickOutside: onClickOutside ?? (() => {}),
    useBooleanToDetermineWhenIsReadyToWatchClicks: isOpen && !!onClickOutside,
    dependencyList: [modalContainerRef, onClickOutside]
  })

  useTrapFocus({
    ref: modalContainerRef
  })

  return (
    <AnimatePresence>
      {isOpen && (
        <StyledOverlay
          key={`${modalKey}-overlay`}
          className={className}
          transition={{
            opacity: { type: 'easeOut', duration: reduceTimeForTest(0.5) }
          }}
          {...ANIMATION_PROPS[animationVariant].overlay}
          /**
           * We could have chosen to don't render the Overlay div at all but that would make the code
           * more complex because of the way how we style the ModalContainer from other components
           * e.g. `const StyledModal = styled(Modal)(() => ({ [`${StyledModalContainer}`]: {`
           * we need the extra div for that styling to work on every case.
           */
          modalHasOverlay={!!ANIMATION_PROPS[animationVariant].overlay}
        >
          <StyledModalContainer
            key={modalKey}
            fullWindow={fullWindow}
            transition={{
              y: {
                type: 'easeOut',
                duration: reduceTimeForTest(0.2),
                delay: reduceTimeForTest(0.1)
              },
              x: {
                type: 'easeOut',
                duration: reduceTimeForTest(0.2),
                delay: reduceTimeForTest(0.1)
              }
            }}
            {...ANIMATION_PROPS[animationVariant].modal}
            /**
             * The dialog role is used to mark up an HTML based application dialog or window that
             * separates content or UI from the rest of the web application or page.
             */
            role="dialog"
            aria-label={modalTitle}
            aria-modal="true"
            ref={modalContainerRef}
          >
            {children}
          </StyledModalContainer>
        </StyledOverlay>
      )}
    </AnimatePresence>
  )
}

export default Modal

const ModalMotionDiv = React.forwardRef<
  typeof motion.div,
  {
    fullWindow: boolean
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>(({ fullWindow, ...props }, ref) => (
  <motion.div {...props} ref={ref as React.Ref<HTMLDivElement>} />
))
ModalMotionDiv.displayName = 'ModalMotionDiv'

export const StyledModalContainer = styled(ModalMotionDiv)<
  {
    fullWindow: boolean
  } & HTMLMotionProps<'div'>
>(
  ({ theme }) => ({
    position: 'relative',
    overflow: 'hidden',
    zIndex: 300,
    padding: theme.space[4],
    background: theme.colors.white,
    border: theme.border,
    borderRadius: theme.radii[3],
    boxShadow: theme.modal.shadow
  }),
  ({ theme, fullWindow }) =>
    fullWindow
      ? theme.mixins.centeredContainer('xl', 3, 0)
      : theme.mixins.centeredContainer('sm', 0, theme.modal.margin)
)

const OverlayMotionDiv = React.forwardRef<
  typeof motion.div,
  { modalHasOverlay: boolean }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>(({ modalHasOverlay, ...props }, ref) => (
  <motion.div {...props} ref={ref as React.Ref<HTMLDivElement>} />
))
OverlayMotionDiv.displayName = 'OverlayMotionDiv'

const StyledOverlay = styled(OverlayMotionDiv)<
  { modalHasOverlay: boolean } & HTMLMotionProps<'div'>
>(
  ({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    [theme.mediaQueries.sm]: {
      marginBottom: theme.space[4]
    },
    [theme.mediaQueries.md]: {
      marginBottom: theme.space[6]
    }
  }),
  ({ theme, modalHasOverlay }) =>
    modalHasOverlay && {
      width: '100%',
      background: theme.colors.overlay
    }
)
