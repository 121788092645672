export const MEMBERSHIPS_CHARGEABLE_TYPE = 'Memberships::Subscription'

export type MembershipBenefitType =
  | 'NoCharge'
  | 'DiscountedPercent'
  | 'DiscountedFixedAmount'

export type MembershipBenefitTranslateId =
  | 'membership-no-charge'
  | 'membership-discounted-percent'
  | 'membership-discounted-fixed-amount'

type MembershipDescriptionIds = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [keys in MembershipBenefitType]: {
    id: MembershipBenefitType
    translateId: MembershipBenefitTranslateId
  }
}

export const MEMBERSHIPS_DECRIPTIONS_IDS: MembershipDescriptionIds = {
  NoCharge: {
    id: 'NoCharge',
    translateId: 'membership-no-charge'
  },
  DiscountedPercent: {
    id: 'DiscountedPercent',
    translateId: 'membership-discounted-percent'
  },
  DiscountedFixedAmount: {
    id: 'DiscountedFixedAmount',
    translateId: 'membership-discounted-fixed-amount'
  }
}
