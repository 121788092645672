import { defined } from 'shared/presenters/presenter'

export let hostname: string = process.env.VIRTUAL_HOST || ''

/**
 * Allow overwriting the hostname provided by process.env.VIRTUAL_HOST because some
 * tests run on the shared directory and this helper changes this value so we can
 * have one file testing the multiple projects.
 */
export const mockHostname = (
  host: string = defined(process.env.VIRTUAL_HOST)
) => (hostname = host)

export const resetHostname = () =>
  (hostname = defined(process.env.VIRTUAL_HOST))

export const setWindowHostname = (host: string) => {
  global.window = Object.create(window)
  Object.defineProperty(window, 'location', {
    value: {
      hostname: host
    },
    writable: true
  })
}
