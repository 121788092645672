import { present, definedNotNull, defined } from 'shared/presenters/presenter'
import { translatablePresenter } from 'shared/presenters/translatablePresenter'
import { Outlets } from 'shared/presenters/graphqlTypes'
import { generateCloudinaryUrl } from 'shared/helpers/generateCloudinaryUrl'
import useTranslation from 'next-translate/useTranslation'
import { CancellationPolicy } from 'shared/enums/outlet'

export class OutletPresenter extends translatablePresenter(present<Outlets>()) {
  currency = () => definedNotNull(this.default_currency)

  description = () => this.translateField('description')
  credit_voucher_tagline = () => this.translateField('credit_voucher_tagline')
  credit_voucher_text = () => this.translateField('credit_voucher_text')
  cancellationPolicy = () => {
    const policy = definedNotNull(this.enthusiast_cancellation_policy)
    /**
     * Return policy text based on policy id number. See Toolset>Policies>Cancellations in business app.
     */
    if (policy === 4) {
      return {
        tagline: this.translateField('custom_cancellation_policy_tagline'),
        html: this.translateField('custom_cancellation_policy_text')
      }
    } else {
      return buildCancellationPolicyJson(policy)
    }
  }

  logoImageURL = () => {
    const imageURL = this.logo_image
    if (imageURL) {
      return generateCloudinaryUrl(imageURL, 'widgetLogo')
    }
  }

  profilePictureImageURL = () => {
    if (!this.profile_picture) {
      return null
    }
    const imageURL = `image/upload/${this.profile_picture.split('image/upload/').slice(-1)[0]}`
    if (imageURL) {
      return generateCloudinaryUrl(imageURL, 'chargeable')
    }
  }

  definedSchedulables = () =>
    defined(this.schedulables).map(schedulable => ({
      id: definedNotNull(schedulable.activity_type?.id),
      name: definedNotNull(schedulable.activity_type?.name)
    }))

  googleMapsImageURL = () => {
    const latitude = this.address?.latitude
    const longitude = this.address?.longitude

    if (!latitude || !longitude) return

    return [
      // GOOGLE_MAPS_IMAGE_PREFIX,
      'https://maps.googleapis.com/maps/api/staticmap?',
      'key=',
      process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
      '&visual_refresh=true',
      '&markers=icon:https://res.cloudinary.com/eola/image/upload/h_30/v1511334399/eola/public_images/gmaps-markerv1.png%7C',
      [latitude, longitude].join(','),
      '&size=',
      240,
      'x',
      200,
      '&scale=',
      2, // retina,
      '&zoom=',
      13
    ].join('')
  }

  googleMapsURL = () => {
    const latitude = this.address?.latitude
    const longitude = this.address?.longitude

    if (!latitude || !longitude) return

    return `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
  }
}

const buildCancellationPolicyJson = (policy: CancellationPolicy) => {
  // Ignore the following rule because we are sure this helper only is used inside a component.
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation('outlets')
  const policyText = CancellationPolicy[policy]
  // for policy = 0 (or anything else) display no cancellation policy
  if (!policyText)
    return {
      tagline: '',
      html: ''
    }
  const html = `<ul><li>${t(`cancellation-policy-${policyText}-1`)}</li><li>${t(
    `cancellation-policy-${policyText}-2`
  )}</li><li>${t(`cancellation-policy-${policyText}-3`)}</li></ul>`
  return {
    tagline: t('cancellation-policy-title'),
    html: html
  }
}
